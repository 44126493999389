import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/lt'
import Loader from './Loader'
import AutocompleteSelect from './AutocompleteSelect'
import DateTimePicker from './DateTimePicker'
import Map from './Map'

class CurrentLocationButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }

    this.getCurrentLocation = this.getCurrentLocation.bind(this)
  }

  getCurrentLocation() {
    this.setState({ isLoading: true })

    navigator.geolocation.getCurrentPosition(location => {
      this.props.setCurrentLocation({ lat: location.coords.latitude, lng: location.coords.longitude })

      this.setState({ isLoading: false })
    })
  }

  render() {
    const { isLoading } = this.state
    
    const { getCurrentLocation } = this

    return (   
      <button
        className="my-location-button"
        onClick={getCurrentLocation}
      >
        {isLoading && (<Loader/>)}
      </button>
    )
  }
}

class RouteSearch extends Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(location.search)

    this.state = {
      origin: JSON.parse(urlParams.get('origin')) || '',
      destination: JSON.parse(urlParams.get('destination')) || '',
      dateTimeOption: urlParams.get('dateTimeOption') || 'departure', // departure, arrival
      dateTime: urlParams.get('dateTime') ? moment(parseFloat(urlParams.get('dateTime'))) : ''
    }

    this.onOptionSelect = this.onOptionSelect.bind(this)
    this.onDateTimeSelect = this.onDateTimeSelect.bind(this)
    this.switchStops = this.switchStops.bind(this)
    this.setOriginOption = this.setOriginOption.bind(this)
    this.setDestinationOption = this.setDestinationOption.bind(this)
    this.setCurrentLocation = this.setCurrentLocation.bind(this)
    this.findRoutes = this.findRoutes.bind(this)
  }

  onOptionSelect(name, option) {
    this.setState({ [name]: option })
  }

  onDateTimeSelect(dateTimeOption, dateTime) {
    this.setState({ dateTimeOption, dateTime })
  }

  switchStops() {
    this.setState({
      origin: this.state.destination,
      destination: this.state.origin
    })
  }

  setOriginOption(option) {
    this.setState({ origin: option })
  }

  setDestinationOption(option) {
    this.setState({ destination: option })
  }

  setCurrentLocation(coords) {
    this.setState({
      origin: {
        name: 'Esama vieta',
        place: { ...coords }
      }
    })
  }

  findRoutes() {
    if (!this.state.origin.place || !this.state.destination.place || this.state.origin === this.state.destination) {
      return
    }

    if (this.state.origin.place === this.state.destination.place) {
      this.setState({
        origin: {
          name: this.state.origin.name,
          place: null
        },
        destination: {
          name: this.state.destination.name,
          place: null
        }
      })

      return
    }

    const locationUrl = `${WP.routesUrl}?origin=${JSON.stringify(this.state.origin)}&destination=${JSON.stringify(this.state.destination)}&dateTimeOption=${this.state.dateTimeOption}&dateTime=${this.state.dateTime}`

    if (this.props.initial) {
      location.href = locationUrl
    } else {
      window.history.pushState(null, null, locationUrl);
      console.log('Origin', this.state.origin)
      console.log('Destination', this.state.destination)
      this.map.fetchRoutes(this.state.origin, this.state.destination, this.state.dateTimeOption, this.state.dateTime)
    }
  }

  render() {
    const { origin, destination, dateTimeOption, dateTime } = this.state
    const { initial, children } = this.props
    const { onOptionsLoad, onOptionSelect, onDateTimeSelect, switchStops, findRoutes, setOriginOption, setDestinationOption, setCurrentLocation } = this

    return (
      <div>
        <div className="route-search">
          <div className="container">
            <div className="row">
              <p className="col route-search-title">Kur norite keliauti?</p>
            </div>

            <div className="row vcenter">
              <div className="col col-tablet-12 full-width mb-tablet-15 current-location-wrapper">
                <AutocompleteSelect
                  name="origin"
                  label="Keliauti iš"
                  onOptionSelect={onOptionSelect}
                  value={origin}
                />
                <CurrentLocationButton setCurrentLocation={setCurrentLocation}/>
              </div>
              <div className="col vbottom tablet-hide">
                <button onClick={switchStops} className="route-switch-button"></button>
              </div>
              <div className="col col-tablet-12 full-width mb-tablet-15">
                <AutocompleteSelect
                  name="destination"
                  label="Keliauti į"
                  onOptionSelect={onOptionSelect}
                  value={destination}
                />
              </div>
              <div className="col col-tablet-12 mb-tablet-15">
                <DateTimePicker dateTimeOption={dateTimeOption} dateTime={dateTime} onDateTimeSelect={onDateTimeSelect}/>
              </div>

              <div className="col col-tablet-12 vbottom">
                <button className="route-search-button" onClick={findRoutes}>Rasti maršrutą</button>
              </div>
            </div>
          </div>
        </div>

        {!initial && (
          <Map
            ref={map => this.map = map}
            origin={origin}
            destination={destination}
            dateTimeOption={dateTimeOption}
            dateTime={dateTime}
            setOriginOption={setOriginOption}
            setDestinationOption={setDestinationOption}
          />
        )}
      </div>
    )
  }
}

export default RouteSearch