import 'babel-polyfill'
import 'url-search-params-polyfill'
import 'whatwg-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import RouteSearch from './components/RouteSearch'
import TimetableRouter from './components/TimetableRouter'

// Render RouteSearch component in index page
const routeSearchComponent = document.querySelector('#route-search-component')
if (routeSearchComponent) {
  ReactDOM.render(
    <RouteSearch initial/>,
    routeSearchComponent
  )
}

// Render RouteSearch component in routes page
const routeSearchApp = document.querySelector('#route-search-app')
if (routeSearchApp) {
  ReactDOM.render(
    <RouteSearch/>,
    routeSearchApp
  )
}

// Render Timetable component in timetable page
const timetable = document.querySelector('#timetable')
if (timetable) {
  ReactDOM.render(
    <TimetableRouter/>,
    timetable
  )
}

if (routeSearchComponent || routeSearchApp || timetable) {
  Raven
  .config('https://e61771c20cf547219a3cdfcc13513b4c@sentry.io/207190')
  .install()
}

// Show search bar
document.querySelector('#search-button').addEventListener('click', e => {
  e.currentTarget.classList.toggle('active')

  document.querySelector('#search-bar').classList.toggle('active')
  document.querySelector('#search-bar-input').focus()
})

document.querySelector('#burger-menu').addEventListener('click', e => {
  e.currentTarget.classList.toggle('active')

  document.querySelector('.menu').classList.toggle('active')
})

const kaNewsElements = document.querySelectorAll('.home-ka-news-item')
const kaNewsMainElements = document.querySelectorAll('.home-ka-news-main-item')

kaNewsElements.forEach(el => {
  el.addEventListener('click', e => {
    kaNewsElements.forEach(el => el.classList.remove('active'))
    kaNewsMainElements.forEach(el => el.classList.remove('active'))

    e.currentTarget.classList.add('active')
    document.querySelector(`[data-item-id="${e.currentTarget.dataset.itemId}"]`).classList.add('active')
  })
})

document.addEventListener('DOMContentLoaded', () => {
  if (location.hash.length > 0) {
    window.scrollTo(0, document.querySelector(`${location.hash}`).offsetTop - 7.5)
    document.querySelector(`${location.hash} input`).checked = true
  }
}, false);
