import React, { Component } from 'react'

import { withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/addons/MarkerClusterer'
import InfoBox from 'react-google-maps/lib/addons/InfoBox'
import moment from 'moment'
import 'moment/locale/lt'
import Loader from './Loader'
import googleMapStyles from '../googleMapStyles'
import laravelApi from '../laravelApi'

const GoogleMapComponent = withGoogleMap(({ children, ...props }) => (
  <GoogleMap {...props}>{children}</GoogleMap>
))

const GoogleMapPopup = ({ title, address, children }) => (
  <InfoBox options={{ maxWidth: 350, alignBottom: true, pixelOffset: new google.maps.Size(-202.5, -48), enableEventPropagation: true }}>
    <div className="stop-popup-container">
      <div className="stop-popup-header">
        <div className="stop-popup-title">
          {title}
        </div>

        <div className="stop-popup-address">
          {address}
        </div>
      </div>

      {children && (
        <div className="popup-content-wrapper">{children}</div>
      )}
    </div>
  </InfoBox>
)

class Map extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stopPins: [],
      ticketPins: [],
      isStopPinsEnabled: false,
      isTicketPinsEnabled: false,
      selectedPin: null,
      selectedStopRoutes: null,
      isStopRoutesLoading: false,
      directions: null,
      selectedRouteIndex: null,
      isPanelExpanded: false,
      isPanelVisible: false,
      isPanelLoading: false,
      isTimetablePanelVisible: false,
      isTimetablePanelLoading: false,
      origin: this.props.origin,
      destination: this.props.destination,
      dateTimeOption: this.props.dateTimeOption,
      dateTime: this.props.dateTime
    }

    this.toggleStopPins = this.toggleStopPins.bind(this)
    this.toggleTicketPins = this.toggleTicketPins.bind(this)
    this.selectPin = this.selectPin.bind(this)
    this.togglePanel = this.togglePanel.bind(this)
    this.fetchRoutes = this.fetchRoutes.bind(this)
    this.viewTimetable = this.viewTimetable.bind(this)
    this.closeTimetablePanel = this.closeTimetablePanel.bind(this)
    this.renderStopPopup = this.renderStopPopup.bind(this)
  }

  componentWillMount() {
    if (this.state.origin.place && this.state.destination.place) {
      this.fetchRoutes(this.state.origin, this.state.destination, this.state.dateTimeOption, this.state.dateTime)
    }
  }

  toggleStopPins(e) {
    this.setState({ isStopPinsEnabled: e.target.checked })

    if (this.state.stopPins.length === 0) {
      laravelApi.getStops()
      .then(stopPins => {
        // let bounds = new google.maps.LatLngBounds()
        // stopPins.forEach(stop => {
          // const latLng = new google.maps.LatLng(stop.stop_lat, stop.stop_lon);
          // bounds.extend(latLng);
        // })

        // console.log(bounds.getSouthWest().toString(), bounds.getNorthEast().toString())

        this.setState({ stopPins })
      })
    }
  }

  toggleTicketPins(e) {
    this.setState({ isTicketPinsEnabled: e.target.checked })

    if (this.state.ticketPins.length === 0) {
      laravelApi.getTicketsSellPlaces()
      .then(ticketPins => {
        this.setState({ ticketPins })
      })
    }
  }

  selectPin(selectedPin) {
    this.setState({ selectedPin })

    const selectedPinSplitted = selectedPin.split('-')
    console.log(selectedPinSplitted)
    if (selectedPinSplitted[0] === 'stop') {
      this.setState({ isStopRoutesLoading: true, selectedStopRoutes: { busAndTrolleybus: [], minibus: [] } })

      laravelApi.getStopTimes(selectedPinSplitted[1])
      .then((routes) => {
        function sortByName(r1, r2) {
          return r1.route_short_name.localeCompare(r2.route_short_name, 'lt', { numeric: true, sensitivity: 'base' })
        }

        function mapRouteTimes(route) {
          const stopTimes = route.stop_times.map(time => {
            const stopTimeSplitted = time.split(':')

            let hours = ~~stopTimeSplitted[0] >= 24 ? ~~stopTimeSplitted[0] - 24 : ~~stopTimeSplitted[0]
            hours = hours < 10 ? `0${hours}` : hours
            return `${hours}:${stopTimeSplitted[1]}`
          })

          route.stop_times = stopTimes

          return route
        }

        const busAndTrolleybus = routes.filter(r => [3, 700, 800].indexOf(r.route_type) > -1).map(mapRouteTimes).sort(sortByName)
        const minibus = routes.filter(r => r.route_type === 1501).map(mapRouteTimes).sort(sortByName)

        this.setState({ isStopRoutesLoading: false, selectedStopRoutes: { busAndTrolleybus, minibus } })
      })
    }
  }

  togglePanel() {
    this.setState({ isPanelExpanded: !this.state.isPanelExpanded })
  }

  fetchRoutes(origin, destination, dateTimeOption, dateTime) {
    this.setState({
      selectedPin: null,
      directions: null,
      selectedRouteIndex: null,
      isPanelExpanded: true,
      isPanelVisible: true,
      isPanelLoading: true,
      isTimetablePanelVisible: false,
      origin,
      destination,
      dateTimeOption,
      dateTime
    })

    const DirectionsService = new google.maps.DirectionsService()

    const transitKey = `${dateTimeOption}Time`
    let transitOptions = {}

    if (moment.isMoment(dateTime)) {
      transitOptions = {
        [transitKey]: dateTime.toDate()
      }
    }

    DirectionsService.route({
      origin: origin.place,
      destination: destination.place,
      travelMode: google.maps.TravelMode.TRANSIT,
      transitOptions,
      provideRouteAlternatives: true,
    }, (directions, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.setState({ isStopPinsEnabled: false, isTicketPinsEnabled: false, isPanelLoading: false, directions, selectedRouteIndex: null })
      } else {
        this.setState({ isStopPinsEnabled: false, isTicketPinsEnabled: false, isPanelLoading: false })
      }
    })
  }

  selectRoute(selectedRouteIndex) {
    this.setState({ selectedRouteIndex })
  }

  viewTimetable() {
    this.setState({ isTimetablePanelVisible: true })
  }

  closeTimetablePanel() {
    this.setState({ isTimetablePanelVisible: false })
  }

  renderStopPopup(stop, setOriginOption, setDestinationOption, viewTimetable) {
    return (
      <GoogleMapPopup title={stop.stop_name} address={stop.address && stop.address.title}>
        {this.state.isStopRoutesLoading ? (
          <Loader/>
        ) : (
          <div>
            <ul className="route-steps">
              {this.state.selectedStopRoutes && this.state.selectedStopRoutes.busAndTrolleybus.map(r => <li className={`route-step-transit type-${r.route_type}`}>{r.route_short_name}</li>)}
            </ul>

            <ul className="route-steps">
              {this.state.selectedStopRoutes && this.state.selectedStopRoutes.minibus.map(r => <li className={`route-step-transit type-${r.route_type}`}>{r.route_short_name}</li>)}
            </ul>
          </div>
        )}

        <div className="stop-popup-button-group">
          <button className="stop-popup-button"
            onClick={() => setOriginOption({
              name: stop.stop_name,
              place: { lat: parseFloat(stop.stop_lat), lng: parseFloat(stop.stop_lon) }
            })}
          >
            Keliauti iš
          </button>
          <button className="stop-popup-button"
          onClick={() => setDestinationOption({
              name: stop.stop_name,
              place: { lat: parseFloat(stop.stop_lat), lng: parseFloat(stop.stop_lon) }
            })}
          >
            Keliauti į
          </button>
        </div>

        <div className="stop-popup-look-all" onClick={viewTimetable}>
          Žiūrėti išvykimus iš pasirinktos stotelės
        </div>
      </GoogleMapPopup>
    )
  }

  render() {
    const {
      isStopPinsEnabled,
      isTicketPinsEnabled,
      stopPins,
      ticketPins,
      selectedPin,
      selectedStopRoutes,
      isStopRoutesLoading,
      directions,
      selectedRouteIndex,
      isPanelExpanded,
      isPanelVisible,
      isPanelLoading,
      isTimetablePanelVisible,
      isTimetablePanelLoading,
      origin,
      destination,
      dateTimeOption,
      dateTime
    } = this.state
    const { setOriginOption, setDestinationOption } = this.props
    const { toggleStopPins, toggleTicketPins, selectPin, togglePanel, renderStopPopup, viewTimetable, closeTimetablePanel } = this

    return (
      <div className="map-with-panel-wrapper">
        {isPanelVisible && (
          <div className={`panel-wrapper ${isPanelExpanded && 'expanded'}`}>
            <div className="panel-scrollable-container">
              <ul className="routes-starting-info">
                <li className="routes-starting-info-origin">{origin.name}</li>
                <li className="routes-starting-info-destination">{destination.name}</li>
                <li className="routes-starting-info-time">{dateTime === '' ? 'Išvykti dabar' : `${dateTimeOption === 'departure' ? 'Išvykti' : 'Atvykti'} ${moment(dateTime).format('YYYY-MM-DD HH:mm')}`}</li>
              </ul>

              {directions ? (
                <ul className="routes-list">
                  {directions.routes.map((route, i) => (
                    <li className={`routes-list-item ${i === selectedRouteIndex ? 'active' : ''}`} onClick={() => this.selectRoute(i)}>
                      <div className="routes-list-times">
                        {/* TODO: Jei route yra iš vieno step, tai departure ir arrival time yra negalimi. */}
                        <span className="routes-list-time">{`${route.legs[0].departure_time.text} - ${route.legs[0].arrival_time.text}`}</span>
                        <span className="routes-list-duration">{parseInt(route.legs[0].duration.value / 60, 10)} min</span>
                      </div>
                      <ul className="route-steps">
                        {route.legs[0].steps.map((step, i) => {
                          switch(step.travel_mode) {
                            case 'WALKING': {
                              return (
                                <li className={`route-step-walking ${i !== 0 ? 'route-step-walking-left' : ''} ${i + 1 !== route.legs[0].steps.length ? 'route-step-walking-right' : ''}`}/>
                              )
                            }

                            case 'TRANSIT': {
                              return (
                                <li className={`route-step-transit ${step.transit.line.vehicle.type.toLowerCase()}`}>{step.transit.line.short_name}</li>
                              )
                            }
                          }
                        })}
                      </ul>

                      <ul className="routes-list-item-detailed-list">
                        {route.legs[0].steps.map((step, i) => {
                          switch(step.travel_mode) {
                            case 'WALKING': {
                              return (
                                <li>
                                  <div className="detailed-list-item-left walking">
                                    <div className="detailed-time">
                                      {i === 0 && route.legs[0].departure_time.text}{i === route.legs[0].steps.length - 1 && route.legs[0].arrival_time.text}
                                    </div>

                                    <div className="detailed-transit-option walking"></div>
                                  </div>
                                  <div className="detailed-list-item-right">
                                    {/*<div className="detailed-list-item-title"></div>*/}

                                    <div className="detailed-list-item-instructions">
                                      {step.instructions}
                                    </div>

                                    <div className="detailed-list-item-meta">
                                      <span>{`${step.duration.text} (${step.distance.text})`}</span>
                                    </div>
                                  </div>
                                </li>
                              )
                            }

                            case 'TRANSIT': {
                              return (
                                <li>
                                  <div className="detailed-list-item-left transit">
                                    <div className="detailed-time">
                                      {step.transit.departure_time.text}
                                    </div>

                                    <div className="detailed-transit-option transit">
                                      <div className={`${step.transit.line.vehicle.type.toLowerCase()}`}>{step.transit.line.short_name}</div>

                                      <div className="detailed-time">
                                        {step.transit.arrival_time.text}
                                      </div>
                                      {/* <button className="expand-button"/> */}
                                    </div>

                                  </div>
                                  <div className="detailed-list-item-right transit">
                                    <div className="detailed-list-item-title">
                                      <label className="get-off-stop">Įlipti</label>
                                      {step.transit.departure_stop.name}
                                    </div>

                                    <div className="detailed-list-item-meta">
                                      <span>{`${step.duration.text} (${step.transit.num_stops} stotelės)`}</span>
                                    </div>

                                    <div className="detailed-list-item-title">
                                      <label className="get-off-stop">Išlipti</label>
                                      {step.transit.arrival_stop.name}
                                    </div>
                                  </div>
                                </li>
                              )
                            }
                          }
                        })}

                        <li>
                          <div className="detailed-list-item-left transit">
                            <div className="detailed-time">
                              {route.legs[0].arrival_time.text}
                            </div>

                            <div className="detailed-transit-option"></div>
                          </div>
                          <div className="detailed-list-item-right">
                            <div className="detailed-list-item-title last">
                              {route.legs[0].end_address}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="route-not-found">{isPanelLoading ? 'Maršrutas ieškomas...' : 'Maršrutas nerastas'}</div>
              )}
            </div>

            <div className="panel-expand-button" onClick={togglePanel}></div>
          </div>
        )}

        {isTimetablePanelVisible && (
          <div className="panel-wrapper floating">
            <div className="panel-scrollable-container">
              <ul className="routes-starting-info">
                <li className="routes-starting-info-destination">{stopPins.find(s => s.stop_id == selectedPin.split('-').pop()).stop_name}</li>
                <li className="routes-starting-info-time">Išvykti dabar</li>
              </ul>

              {selectedStopRoutes ? (
                <div>
                  <ul className="stop-route-times">
                    {selectedStopRoutes.busAndTrolleybus.map(r => (
                      <li className={`type-${r.route_type}`}>
                        <div className="stop-route-times-heading">{r.route_short_name}</div>

                        <ul>
                          {r.stop_times.map(time => (<li>{time.substr(0, 5)}</li>))}
                        </ul>
                      </li>
                    ))}

                    {selectedStopRoutes.minibus.map(r => (
                      <li className={`type-${r.route_type}`}>
                        <div className="stop-route-times-heading">{r.route_short_name}</div>

                        <ul>
                          {r.stop_times.map(time => (<li>{time.substr(0, 5)}</li>))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <Loader/>
              )}
            </div>

            <div className="panel-close-button" onClick={closeTimetablePanel}></div>
          </div>
        )}


        <div className="map-wrapper">
          <GoogleMapComponent
            containerElement={<div className="map" />}
            mapElement={<div style={{height: '100%'}} />}
            defaultZoom={15}
            defaultCenter={{ lat: 54.8985, lng: 23.9036 }}
            defaultOptions={{ styles: googleMapStyles, disableDefaultUI: true, zoomControl: true, streetViewControl: true }}
          >
            {/*<MarkerClusterer>*/}
              {isStopPinsEnabled && stopPins.map((stop, i) => (
                <Marker
                  position={{ lat: parseFloat(stop.stop_lat), lng: parseFloat(stop.stop_lon) }}
                  icon={{url: `/wp-content/themes/kvt/public/images/icon-map-bus.png`, scaledSize: new google.maps.Size(24, 29)}}
                  key={`stop-${stop.stop_id}`}
                  onClick={() => selectPin(`stop-${stop.stop_id}`)}
                >
                  {selectedPin === `stop-${stop.stop_id}` && renderStopPopup(stop, setOriginOption, setDestinationOption, viewTimetable)}
                </Marker>
              ))}

              {isTicketPinsEnabled && ticketPins.map((ticket, i) => {
                return (
                <Marker
                  position={{ lat: parseFloat(ticket.lat), lng: parseFloat(ticket.lon) }}
                  icon={{url: `/wp-content/themes/kvt/public/images/icon-map-ticket.png`, scaledSize: new google.maps.Size(24, 29)}}
                  key={`ticket-${i}`}
                  onClick={() => selectPin(`ticket-${i}`)}
                >
                  {selectedPin === `ticket-${i}` && (
                    <GoogleMapPopup title={ticket.name} address={ticket.address}/>
                  )}
                </Marker>
              )})}
            {/*</MarkerClusterer>*/}

            {(directions && selectedRouteIndex !== null) && (
              <DirectionsRenderer directions={directions} routeIndex={selectedRouteIndex} options={{ suppressInfoWindows: true }}/>
            )}
          </GoogleMapComponent>

          <div className="map-pins">
            <label htmlFor="stops-checkbox">
              <input type="checkbox" id="stops-checkbox" checked={isStopPinsEnabled} onChange={toggleStopPins}/>Stotelės
            </label>

            <label htmlFor="tickets-checkbox">
              <input type="checkbox" id="tickets-checkbox" checked={isTicketPinsEnabled} onChange={toggleTicketPins}/>Bilietų pardavimo vietos
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default Map