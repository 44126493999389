import React, { Component } from 'react'

import Datetime from 'react-datetime'
import moment from 'moment'
import 'moment/locale/lt'

class DateTimePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dateTimeOption: this.props.dateTimeOption,
      dateTime: this.props.dateTime > 0 ? this.props.dateTime : moment(),
      isOpen: false
    }

    this.toggleOpen = this.toggleOpen.bind(this)
    this.onDateSelect = this.onDateSelect.bind(this)
    this.onTimeSelect = this.onTimeSelect.bind(this)
    this.onDateTimeOptionSelect = this.onDateTimeOptionSelect.bind(this)
    this.onDateTimeSelect = this.onDateTimeSelect.bind(this)
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onDateSelect(dateTime) {
    let dt = moment(this.state.dateTime)

    dt = moment(dt).set('year', dateTime.get('year'))
    dt = moment(dt).set('month', dateTime.get('month'))
    dt = moment(dt).set('date', dateTime.get('date'))

    this.setState({ dateTime: dt })
  }

  onTimeSelect(dateTime) {
    let dt = moment(this.state.dateTime)

    dt = moment(dt).set('hour', dateTime.get('hour'))
    dt = moment(dt).set('minute', dateTime.get('minute'))

    this.setState({ dateTime: dt })
  }

  onDateTimeOptionSelect(e) {
    this.setState({ dateTimeOption: e.target.value })
  }

  onDateTimeSelect(dateTimeOption, dateTime) {
    this.toggleOpen()

    this.props.onDateTimeSelect(dateTimeOption, dateTime)
  }

  render() {
    const { dateTimeOption, dateTime, isOpen } = this.state
    const { dateTime: dateTimeProp } = this.props
    const { toggleOpen, onDateSelect, onTimeSelect, onDateTimeOptionSelect, onDateTimeSelect } = this

    const selectedDateTime = moment.isMoment(dateTimeProp) ? moment(dateTimeProp).format('YYYY-MM-DD HH:mm') : ''

    return (
      <div>
        <label htmlFor="route-date">Data / Laikas</label>
        <input id="route-date" className="route-date" type="text" placeholder="Išvykti dabar" value={selectedDateTime} onClick={toggleOpen}/>

        {isOpen && (
          <div className="datetime-picker-container">
            <div className="datetime-picker">
              <div className="datetime-picker-label">Pasirinkite kelionės laiką</div>
              <Datetime input={false} locale="lt" timeFormat={false} value={dateTime} onChange={onDateSelect} className="date-picker" renderDay={(props, currentDate, selectedDate) => (<td><span {...props}>{currentDate.date()}</span></td>)}/>

              <div className="time-picker-container">
                <Datetime input={false} locale="lt" dateFormat={false} timeFormat="HH:mm" value={dateTime} onChange={onTimeSelect} className="time-picker"/>

                <div className="departure-arrival-radio-group">
                  <label htmlFor="type-departure" className="radio-label">
                    <input type="radio" name="type" id="type-departure" value={'departure'} checked={dateTimeOption === 'departure'} onChange={onDateTimeOptionSelect}/> Išvykti
                  </label>

                  <label htmlFor="type-arrive" className="radio-label">
                    <input type="radio" name="type" id="type-arrive" value={'arrival'} checked={dateTimeOption === 'arrival'} onChange={onDateTimeOptionSelect}/> Atvykti
                  </label>
                </div>
              </div>

              <div className="datetime-picker-footer">
                <button className="datetime-picker-leave-now" onClick={() => onDateTimeSelect('departure', '')}>Išvykti dabar</button>
                <button className="datetime-picker-continue" onClick={() => onDateTimeSelect(dateTimeOption, dateTime)}>Tęsti</button>
              </div>

              <button className="datetime-picker-close" onClick={toggleOpen}></button>
            </div>

            <div className="datetime-picker-backdrop" onClick={toggleOpen}></div>
          </div>
        )}
      </div>
    )
  }
}

export default DateTimePicker