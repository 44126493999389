const baseUrl = 'https://kvt.lt/api/public/api/v1'

const api = {
  getStops() {
    return fetch(`${baseUrl}/stops`).then(response => response.json())
  },

  getStopTimes(stopId) {
    const d = new Date()
    const timestamp = (d.getTime() - d.getTimezoneOffset() * 60 * 1000) / 1000 | 0

    return fetch(`${baseUrl}/stop-times/${stopId}?datetime=${timestamp}`).then(response => response.json())
  },

  getTicketsSellPlaces() {
    return fetch(`${baseUrl}/ticket-sell-places`).then(response => response.json())
  },

  getRoutes() {
    return fetch(`${baseUrl}/routes`).then(response => response.json())
  },

  getRouteTimetable(routeId) {
    return fetch(`${baseUrl}/route-timetable/${routeId}`).then(response => response.json())
  },

  getRoutePath(shapeId) {
    return fetch(`${baseUrl}/route-path/${shapeId}`).then(response => response.json())
  }
}

export default api
