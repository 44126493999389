import React, { Component } from 'react'

import Select from 'react-select'

const AUTOCOMPLETE_BOUNDS = new google.maps.LatLngBounds(
  new google.maps.LatLng(54.76856, 23.762359999999944),
  new google.maps.LatLng(54.97643, 24.13094000000001)
)

class AutocompleteSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
      hasErrors: false
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidMount() {
    const autocompleteInput = new google.maps.places.Autocomplete(this.input, {
      bounds: AUTOCOMPLETE_BOUNDS,
      strictBounds: true
    })

    autocompleteInput.addListener('place_changed', () => {
      const place = autocompleteInput.getPlace()

      this.setState({ value: { name: place.name, place: { placeId: place.place_id }}, hasErrors: false })
      this.props.onOptionSelect(this.props.name, { name: place.name, place: { placeId: place.place_id }})
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value, hasErrors: nextProps.value.place === null })
  }

  onChange(e) {
    this.setState({ value: { name: e.target.value, place: null }})
  }

  onBlur(e) {
    if (!this.state.value.place) {
      this.props.onOptionSelect(this.props.name, this.state.value)
      this.setState({ hasErrors: true })
    }
  }

  render() {
    const { value, hasErrors } = this.state
    const { name, label, options } = this.props
    const { onChange, onBlur } = this

    return (
      <div className="autocomplete-wrapper">
        <label htmlFor={name}>{label}</label>
        <input
          className={`${hasErrors && 'autocomplete-input-error'}`}
          placeholder="Įveskite adresą..."
          type="text"
          ref={input => this.input = input}
          onChange={onChange}
          onBlur={onBlur}
          value={value.name}
        />
        
        {hasErrors && (<span className="autocomplete-error"/>)}
      </div>
    )
  }
}

export default AutocompleteSelect