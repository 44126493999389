import React, { Component } from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Timetable from './Timetable'
import TimetableDetail from './TimetableDetail'

const TimetableRouter = () => (
  <div className="container">
    <div className="row">
      <div className="col">
        <h1 className="timetable-title">Tvarkaraščiai</h1>
      </div>
    </div>

    <Router basename="/tvarkarasciai">
      <Switch>
        <Route exact path="/" component={Timetable}/>
        <Route path="/:routeId" component={TimetableDetail}/>
      </Switch>
    </Router>
  </div>
)

export default TimetableRouter