import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import Loader from './Loader'
import laravelApi from '../laravelApi'

class Timetable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      busRoutes: [],
      minibusRoutes: [],
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    function sortByName(r1, r2) {
      return r1.route_short_name.localeCompare(r2.route_short_name, 'lt', { numeric: true, sensitivity: 'base' })
    }

    laravelApi.getRoutes()
    .then(routes => {
      this.setState({
        busRoutes: routes.filter(r => r.route_id.indexOf('minibus') === -1).sort(sortByName),
        minibusRoutes: routes.filter(r => r.route_id.indexOf('minibus') > -1).sort(sortByName),
        isLoading: false
      })
    })
  }

  renderTimetableGroup(title, routes, isBus, isLoading) {
    const itemClass = isBus ? 'bus' : 'minibus'

    return (
      <div ref={ref => ref && ref.scrollIntoView(true)}>
        <div className="row">
          <div className="col">
            <div className={`timetable-item timetable-item-title timetable-${itemClass}-icon`}>{title}</div>
          </div>
        </div>

        {isLoading ? (
          <Loader/>
        ) : (
          <div className="row timetable-group">
            {routes.map(route => (
              <div className="col-4 col-tablet-6 col-mobile-12">
                <Link to={`/${route.route_id}`}>
                  <div className={`timetable-item ${itemClass}`}>
                    <span className="timetable-nr">{route.route_short_name}</span>
                    <span className="timetable-text">{route.route_long_name}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { busRoutes, minibusRoutes, isLoading } = this.state

    return (
      <div>
        {this.renderTimetableGroup('Autobusai ir troleibusai', busRoutes, true, isLoading)}
        {this.renderTimetableGroup('Maršrutiniai taksi', minibusRoutes, false, isLoading)}
      </div>
    )
  }
}

export default Timetable