import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withGoogleMap, GoogleMap, Polyline, Marker } from 'react-google-maps'

import Loader from './Loader'
import googleMapStyles from '../googleMapStyles'
import laravelApi from '../laravelApi'

const DAY_NAMES = {
  '1,0,0,0,0,0,0': 'Pirmadieniais',
  '0,1,0,0,0,0,0': 'Antradieniais',
  '0,0,1,0,0,0,0': 'Trečiadieniais',
  '0,0,0,1,0,0,0': 'Ketvirtadieniais',
  '0,0,0,0,1,0,0': 'Penktadieniais',
  '1,1,1,1,1,0,0': 'Darbo dienomis',
  '0,0,0,0,0,1,0': 'Šeštadieniais',
  '0,0,0,0,0,0,1': 'Sekmadieniais',
  '0,0,0,0,0,1,1': 'Savaitgaliais ir švenčių dienomis'
}

const sortDaysArray = (d1, d2) => {
  const dayWeights = {
    '1,0,0,0,0,0,0': 0,
    '0,1,0,0,0,0,0': 1,
    '0,0,1,0,0,0,0': 2,
    '0,0,0,1,0,0,0': 3,
    '0,0,0,0,1,0,0': 4,
    '1,1,1,1,1,0,0': 5,
    '0,0,0,0,0,1,0': 6,
    '0,0,0,0,0,0,1': 7,
    '0,0,0,0,0,1,1': 8
  }

  return dayWeights[d1] - dayWeights[d2]
}

const GoogleMapComponent = withGoogleMap(({ children, onMapLoad, ...props }) => (
  <GoogleMap ref={onMapLoad} {...props}>{children}</GoogleMap>
))

function nightTimeToDayTime(time) {
  const stopTimeSplitted = time.split(':')

  let hours = ~~stopTimeSplitted[0] >= 24 ? ~~stopTimeSplitted[0] - 24 : ~~stopTimeSplitted[0]
  hours = hours < 10 ? `0${hours}` : hours

  return `${hours}:${stopTimeSplitted[1]}`
}

class TimetableDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isRoutePickerOpen: false,
      isMapOpen: false,
      trips: null,
      tripPath: null,
      selectedTrip: null,
      selectedDay: null,
      selectedStop: null,
      selectedTime: null,
      isLoadingTrips: false,
      isLoadingPath: false
    }

    this.mapRef = null

    this.toggleRoutePicker = this.toggleRoutePicker.bind(this)
    this.toggleMapView = this.toggleMapView.bind(this)
    this.getRoutePath = this.getRoutePath.bind(this)
    this.selectTrip = this.selectTrip.bind(this)
    this.selectDay = this.selectDay.bind(this)
    this.selectStop = this.selectStop.bind(this)
    this.selectTime = this.selectTime.bind(this)
    this.onMapLoad = this.onMapLoad.bind(this)
    this.renderStopTimes = this.renderStopTimes.bind(this)
  }

  componentWillMount() {
    this.setState({ isLoadingTrips: true })

    laravelApi.getRouteTimetable(this.props.match.params.routeId)
    .then(trips => {
      const selectedTrip = Object.keys(trips)[0]
      const selectedDay = Object.keys(trips[selectedTrip].timetable).sort(sortDaysArray)[0]

      this.getRoutePath(selectedTrip)

      this.setState({
        trips,
        selectedTrip,
        selectedDay,
        selectedStop: 0,
        selectedTime: 0,
        isLoadingTrips: false
      })
    })
  }

  toggleRoutePicker() {
    this.setState({ isRoutePickerOpen: !this.state.isRoutePickerOpen })
  }

  toggleMapView() {
    this.setState({ isMapOpen: !this.state.isMapOpen })
  }

  getRoutePath(selectedTrip) {
    this.setState({ isLoadingPath: true })

    laravelApi.getRoutePath(selectedTrip)
    .then(tripPath => {
      this.setState({ tripPath, isLoadingPath: false })
    })
  }

  selectTrip(selectedTrip) {
    const selectedDay = Object.keys(this.state.trips[selectedTrip].timetable).sort(sortDaysArray)[0]

    this.setState({ isLoadingPath: true })

    this.getRoutePath(selectedTrip)

    this.setState({ isRoutePickerOpen: false, selectedTrip, selectedDay, selectedStop: 0, selectedTime: 0 })
  }

  selectDay(selectedDay) {
    this.setState({ selectedDay, selectedTime: 0 })
  }

  selectStop(selectedStop) {
    this.setState({ selectedStop })
  }

  selectTime(selectedTime) {
    this.setState({ selectedTime })
  }

  onMapLoad(map) {
    if (map) {
      this.mapRef = map
    }

    let bounds = new google.maps.LatLngBounds()

    this.state.tripPath.forEach(coord => {
      bounds.extend(new google.maps.LatLng(coord.lat, coord.lng))
    })

    this.mapRef.fitBounds(bounds)
  }

  renderStopTimes(stopTimes) {
    const newStopTimes = stopTimes.reduce((stopTimeObj, stopTime, index) => {
      const stopTimeSplitted = stopTime.departure_time.split(':')

      if (stopTimeObj[stopTimeSplitted[0]] === undefined) {
        stopTimeObj[stopTimeSplitted[0]] = []
      }

      stopTime.departure_time = nightTimeToDayTime(stopTime.departure_time)
      stopTime.index = index

      stopTimeObj[stopTimeSplitted[0]].push(stopTime)

      return stopTimeObj
    }, {})

    return (
      <ul className="route-stop-times-list">
        {Object.keys(newStopTimes).sort((t1, t2) => ~~t1 - ~~t2).map(stopHour => (
          <li>
            <ul>
              {newStopTimes[stopHour].map(time => (
                <li onClick={() => this.selectTime(time.index)} className={`${time.wheelchair_accessible && 'wheelchair'} ${this.state.selectedTime === time.index && 'active'}`}>{time.departure_time}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const { isMapOpen, isRoutePickerOpen, trips, tripPath, selectedTrip, selectedDay, selectedStop, selectedTime, isLoadingTrips, isLoadingPath } = this.state
    const { toggleRoutePicker, toggleMapView, selectTrip, selectStop, selectTime, selectDay, renderStopTimes, onMapLoad } = this

    return (
      <div ref={ref => ref && ref.scrollIntoView(true)}>
        <div className="row">
          <div className="col-12">
            <div className="timetable-header">
              <Link to="/" className="go-back-to-timetable">
                Kiti maršrutai
              </Link>

              {!isMapOpen ? (
                <button className="timetable-switch-button" onClick={toggleMapView}>
                  Rodyti stotelių žemėlapį
                </button>
              ) : (
                <button className="timetable-switch-button times" onClick={toggleMapView}>
                  Rodyti laiko lentelę
                </button>
              )}

            </div>
          </div>
        </div>

        {isLoadingTrips ? (
          <Loader/>
        ) : (
          trips ? (
            <div className="row">
              <div className="col-4 col-mobile-12">
                <div className="timetable-route-picker">
                  <span className="timetable-route-picker-default-nr">{this.props.match.params.routeId.split('_').pop()}</span>
                  <span className="timetable-route-picker-default-text" onClick={toggleRoutePicker}>{`${trips[selectedTrip].trip_start} - ${trips[selectedTrip].trip_end}`}</span>
                  <ul className={`timetable-route-picker-options-list ${isRoutePickerOpen && 'active'}`}>
                    {Object.keys(trips).map(key => (
                      <li key={key} onClick={() => selectTrip(key)}>
                        <span>
                          {`${trips[key].trip_start} - ${trips[key].trip_end}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <ul className="timetable-route-stops-list">
                  {trips[selectedTrip].timetable[selectedDay].map((stop, i) => (
                    <li key={i} className={i === selectedStop ? 'active' : ''} onClick={() => selectStop(i)}>
                      {selectedTime !== null && (
                        <span className="stop-time">{stop.stop_times[selectedTime] && nightTimeToDayTime(stop.stop_times[selectedTime].departure_time)}</span>
                      )}

                      <span className="stop-name">{stop.stop_name}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {!isMapOpen ? (
                <div className="col-8 col-mobile-12">
                  <ul className="timetable-route-tabs-list">
                      {Object.keys(trips[selectedTrip].timetable).sort(sortDaysArray).map(day => (<li className={day === selectedDay ? 'active' : ''} onClick={() => this.selectDay(day)}>{DAY_NAMES[day]}</li>))}
                  </ul>

                  {renderStopTimes(trips[selectedTrip].timetable[selectedDay][selectedStop].stop_times)}
                </div>
              ) : (
                <div className="col-8 col-mobile-12 map-routes-container">
                  {isLoadingPath ? (
                    <Loader className="route-path-loader"/>
                  ) : (
                    <GoogleMapComponent
                      onMapLoad={onMapLoad}
                      containerElement={<div className="map" />}
                      mapElement={<div style={{height: '100%'}} />}
                      defaultZoom={15}
                      defaultCenter={{ lat: 54.8985, lng: 23.9036 }}
                      defaultOptions={{ styles: googleMapStyles, disableDefaultUI: true, zoomControl: true, streetViewControl: true }}
                    >
                      {tripPath.length > 0 && <Polyline path={tripPath} options={{strokeColor: '#1d3d8c', strokeWeight: 5}}/>}

                      {trips[selectedTrip].timetable[selectedDay].map((stop, i) => (
                        <Marker
                          position={{ lat: parseFloat(stop.stop_lat), lng: parseFloat(stop.stop_lon) }}
                          icon={{ url: `/wp-content/themes/kvt/public/images/icon-map-bus${selectedStop == i ? '-active' : ''}.png`, scaledSize: selectedStop == i ? new google.maps.Size(50, 50) : new google.maps.Size(24, 29) }}
                          key={`stop-${i}`}
                          onClick={() => selectStop(i)}
                        />
                      ))}
                    </GoogleMapComponent>
                  )}
                </div>
              )}
            </div>
          ) : null
        )}

        <div className="row">
          <div className="col-12">
            <div className="timetable-explanations-title">Paaiškinimai:</div>

            <p className="timetable-explanations">
              Dėl besikeičiančių eismo sąlygų galimi maršrutinio transporto eismo nukrypimai nuo tvarkaraščio.<br/>
              Pelės žymekliu spustelėjus išvykimo laiką bus parodyti išvykimo laikai iš visų to mašinos reiso stotelių.<br/>
              Geltonu fonu pažymėti laikai, kuriais važiuoja žemagrindžiai autobusai, pritaikyti neįgaliesiems.<br/>
              Raudona spalva pažymėtas išvykimo laikas rodo, kad autobusas/troleibusas važiuoja į parką. Kita spalva žymi pakeistą reiso trasą. Atvedus pelės žymeklį ties spalvotu išvykimo laiku, bus parodytas reiso krypties pavadinimas. Paspaudus išvykimo laiką, bus parodyta reiso stotelių seka ir laikai.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default TimetableDetail