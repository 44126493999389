'use strict';

exports.__esModule = true;

var _reactRouter = require('react-router');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _reactRouter.Route;
  }
});